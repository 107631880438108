import orthopedic from './orthopedy.jpg';
import neurologic from './neuro.jpg';
import geriatric from './geriatric.jpg';
import functionall from './functional.jpg';
import paediatricc from './paediatric.jpg';
import cardioo from './cardio.jpg';
import covidd from './covid.jpeg';
import onlinee from './online.jpg';
// import functionall from './functional.jpg';

export const orthopedy = orthopedic;
export const neurology = neurologic;
export const geriatry = geriatric;
export const functional = functionall;
export const paediatric = paediatricc;
export const cardio = cardioo;
export const covid = covidd;
export const online = onlinee;